import React from "react";

function Document() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="30"
      height="30"
      enableBackground="new 0 0 492 492"
      version="1.1"
      viewBox="0 0 492 492"
      xmlSpace="preserve"
    >
      <path
        fill="#0EE0B8"
        d="M402 0H90C76.4 0 66 10.4 66 24v320l154.4 148H402c13.6 0 24-10.4 24-24V24c0-13.6-10.4-24-24-24z"
      ></path>
      <path
        fill="#FF7900"
        d="M402 0H90C76.4 0 66 10.4 66 24v320l154.4 148H402c13.6 0 24-10.4 24-24V24c0-13.6-10.4-24-24-24z"
      ></path>
      <path
        fill="#FF6700"
        d="M426 383.2V24c0-13.6-10.4-24-24-24H90C76.4 0 66 10.4 66 24"
      ></path>
      <path fill="#FF4E00" d="M426 383.2V24c0-13.6-10.4-24-24-24H205.2"></path>
      <path
        fill="#FF6700"
        d="M200.4 348.8v120c0 1.6 0 3.2.8 4.8l18.4 17.6c1.6 0 2.4.8 4 .8h125.6L200.4 348.8z"
      ></path>
      <path
        fill="#FF4E00"
        d="M200.4 348.8v120c0 1.6 0 3.2.8 4.8l18.4 17.6c1.6 0 2.4.8 4 .8h64l-72-120-15.2-23.2z"
      ></path>
      <path
        fill="#FFF"
        d="M220.4 491.2v-124c0-13.6-10.4-24-24-24H66l154.4 148z"
      ></path>
      <path fill="#FFEDCF" d="M66 344L220.4 491.2 220.4 491.2 220.4 368"></path>
      <path
        fill="#FCC054"
        d="M77.2 344V24c0-13.6 10.4-24 24-24H90.8c-13.6 0-24 10.4-24 24v320h10.4z"
      ></path>
      <g fill="#FF4E00">
        <path d="M171.6 166.4H198c16 0 29.6-12.8 29.6-29.6v-25.6c0-16-12.8-29.6-29.6-29.6h-25.6c-16 0-29.6 12.8-29.6 29.6v25.6c0 16 12.8 29.6 28.8 29.6zM274.8 104.8h97.6c6.4 0 11.2-4.8 11.2-11.2 0-6.4-4.8-11.2-11.2-11.2h-97.6c-6.4 0-11.2 4.8-11.2 11.2-.8 5.6 4.8 11.2 11.2 11.2zM371.6 143.2h-96.8c-6.4 0-11.2 4.8-11.2 11.2s4.8 11.2 11.2 11.2h97.6c6.4 0 11.2-4.8 11.2-11.2 0-6.4-5.6-11.2-12-11.2zM371.6 204.8H146.8c-6.4 0-11.2 4.8-11.2 11.2s4.8 11.2 11.2 11.2h225.6c6.4 0 11.2-4.8 11.2-11.2 0-6.4-5.6-11.2-12-11.2zM371.6 266.4H146.8c-6.4 0-11.2 4.8-11.2 11.2s4.8 11.2 11.2 11.2h225.6c6.4 0 11.2-4.8 11.2-11.2s-5.6-11.2-12-11.2zM371.6 327.2h-96.8c-6.4 0-11.2 4.8-11.2 11.2s4.8 11.2 11.2 11.2h97.6c6.4 0 11.2-4.8 11.2-11.2s-5.6-11.2-12-11.2z"></path>
      </g>
      <g fill="#FFF">
        <path d="M164.4 154.4H190c16 0 29.6-12.8 29.6-29.6V99.2c0-16-12.8-29.6-29.6-29.6h-25.6c-16 0-29.6 12.8-29.6 29.6v26.4c0 16 13.6 28.8 29.6 28.8zM266.8 92.8h97.6c6.4 0 11.2-4.8 11.2-11.2 0-6.4-4.8-11.2-11.2-11.2h-97.6c-6.4 0-11.2 4.8-11.2 11.2 0 6.4 4.8 11.2 11.2 11.2zM364.4 131.2h-97.6c-6.4 0-11.2 4.8-11.2 11.2s4.8 11.2 11.2 11.2h97.6c6.4 0 11.2-4.8 11.2-11.2s-4.8-11.2-11.2-11.2zM364.4 192.8H138.8c-6.4 0-11.2 4.8-11.2 11.2s4.8 11.2 11.2 11.2h225.6c6.4 0 11.2-4.8 11.2-11.2s-4.8-11.2-11.2-11.2zM364.4 254.4H138.8c-6.4 0-11.2 4.8-11.2 11.2 0 6.4 4.8 11.2 11.2 11.2h225.6c6.4 0 11.2-4.8 11.2-11.2 0-5.6-4.8-11.2-11.2-11.2zM364.4 316h-97.6c-6.4 0-11.2 4.8-11.2 11.2 0 6.4 4.8 11.2 11.2 11.2h97.6c6.4 0 11.2-4.8 11.2-11.2 0-5.6-4.8-11.2-11.2-11.2z"></path>
      </g>
    </svg>
  );
}

export default Document;
